import React, { useMemo } from 'react';
import { I18nMessages } from '../FeatureAppTypes';

interface State {
  readonly i18nMessages: I18nMessages;
}

interface ContextValue {
  readonly children: JSX.Element;
  readonly i18nMessages: I18nMessages;
}

export const I18nContext = React.createContext<State>({} as State);

export const I18nContextProvider = (props: ContextValue): JSX.Element => {
  const { children, i18nMessages } = props;

  const state = useMemo(() => {
    return {
      i18nMessages,
    };
  }, [i18nMessages]);

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
};
