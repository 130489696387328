import { audiDarkTheme, audiLightTheme, ThemeProvider } from '@audi/audi-ui-react';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { useContent } from '@oneaudi/feature-app-utils';
import React, { useContext, useEffect, useState } from 'react';
import GallerySection from './components/Gallery';
import { Context } from './context';
import { AsyncFeatureAppProps, InitialState, ThemeGallery, Content } from './FeatureAppTypes';
import { EditorSlideContent } from './EditorContentTypes';
import { useI18n } from './i18n';
import { I18nContextProvider } from './i18n/i18nContext';
import { formatWltpData } from './utils/mapToSlides';

import { createInitialState } from './utils/stateUtils';

const ProductHighlightsModule: React.FunctionComponent<InitialState> = (
  initialState: InitialState,
) => {
  const { content, slides } = initialState;
  const mappedSlideProps: EditorSlideContent[] = slides.map((slide) => {
    return {
      ...slide,
      wltpData: formatWltpData(slide?.wltpData),
    };
  });
  const theme = content.themeGallery === ThemeGallery.DARK ? audiDarkTheme : audiLightTheme;
  const { contentService, renderModeService } = useContext(Context);
  const i18nTexts = {
    start: useI18n('start'),
    pause: useI18n('pause'),
    previous: useI18n('previous'),
    next: useI18n('next'),
    jumpTo: useI18n('jump.to'),
    mute: useI18n('mute'),
    unmute: useI18n('unmute'),
  };

  return (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <ThemeProvider theme={theme}>
        <GallerySection theme={theme} slides={mappedSlideProps} i18nTexts={i18nTexts} />
      </ThemeProvider>
    </UniversalEditorProvider>
  );
};

const AsyncFeatureApp: React.FunctionComponent<AsyncFeatureAppProps> = ({
  asyncStateHolder,
}: AsyncFeatureAppProps) => {
  // when asyncStateHolder is an object it represents the serialized state
  // coming from the server ready to be used as the
  // initial state
  const [state, setState] = useState<InitialState | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );
  const { localeService, i18nService, logger, vueFormatterService } = useContext(Context);
  const content = useContent<Content>();
  useEffect(() => {
    let mounted = true;
    if (state && content) {
      createInitialState(content, localeService, i18nService, vueFormatterService, logger).then(
        (data) => mounted && setState(data),
      );
    }
    return () => {
      mounted = false;
    };
  }, [content]);

  useEffect(() => {
    // when asyncStateHolder is a function it means the state could not be properly serialized by the server and it is
    // not available on the client. In that case this effect will try to fetch the state
    // as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then(setState);
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }
  }, []);

  if (!state) {
    return null;
  }
  return (
    <I18nContextProvider i18nMessages={state.i18nMessages}>
      <ProductHighlightsModule {...state} />
    </I18nContextProvider>
  );
};

export default AsyncFeatureApp;
