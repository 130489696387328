import { RenderMode } from '@oneaudi/render-mode-service';
import { AEMContent, AEMSlide, Asset, AssetSource } from '../EditorContentTypes';
import { Content, SlideContent, ThemeGallery } from '../FeatureAppTypes';

export const mapHeadlessContent = (
  content: Content | AEMContent,
  renderMode: RenderMode,
): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalizedContent: Content = {
      themeGallery:
        content.fields.themeGallery === 'light' ? ThemeGallery.LIGHT : ThemeGallery.DARK,
      slides: content.fields.slides.map(mapAEMSlideToSlideContent, renderMode),
    };
    return normalizedContent;
  }
  return content as Content;
};

export const mapAEMSlideToSlideContent = (
  aemSlide: AEMSlide,
  renderMode: RenderMode,
): SlideContent => {
  const { headline, subline, wltp, additionalText } = aemSlide.fields;
  const {
    assetType,
    alt,
    backgroundColor,
    source,
    sourceMobile,
    title,
    autoPlay,
    loop,
    hideMuteButton,
    svgUrl,
  } = aemSlide.fields.asset.fields;
  let assetValue: AssetSource;
  let mobileAssetValue: string | undefined;

  /* eslint-disable no-case-declarations */
  switch (assetType) {
    case 'image' || 'svg':
      if (typeof source === 'object' && 'contentPath' in source) {
        const { path } = source;
        if (path) {
          assetValue = {
            image: [
              {
                imageFormats: {
                  'web-largest': providePlaceHolderImage(
                    path,
                    'https://dummyimage.com/1920x1280',
                    renderMode,
                  ) as string,
                },
              },
            ],
          };
        }
      }
      break;
    case 'video':
      if (typeof source === 'object' && 'path' in source) {
        if (source.path) {
          assetValue = providePlaceHolderImage(
            source.path,
            'https://dummyimage.com/1920x1280',
            renderMode,
          );
        }
        if (typeof sourceMobile === 'object' && 'path' in sourceMobile) {
          mobileAssetValue = providePlaceHolderImage(
            sourceMobile.path,
            'https://dummyimage.com/1920x1280',
            renderMode,
          );
        }
      }
      break;
    default:
      // Handle unknown asset types
      break;
  }

  const asset: Asset = {
    assetType,
    source: {
      assetValue: assetValue ?? '',
    },
    sourceMobile: {
      assetValue: mobileAssetValue ?? '',
    },
    alt,
    backgroundColor,
    title: title ?? '',
    autoPlay: autoPlay ?? false,
    loop: loop ?? false,
    hideMuteButton: hideMuteButton ?? false,
    svgUrl: svgUrl ?? '',
  };

  return {
    headline,
    subline,
    asset,
    wltp: wltp ?? [],
    additionalText: additionalText ?? '',
  };
};

/**
 * If a path is has no content but we are in a the RenderMode.EDIT
 * Then we set a placeholder text to make the editing in the UE possible.
 * Otherwise the element may not be shown, which makes it impossible to edit it in the
 * UE
 *
 * @param path
 * @param placeholder
 * @param renderMode
 * @returns
 */
function providePlaceHolderImage(path: string, placeholder: string, renderMode: RenderMode) {
  if (path === '' && renderMode === RenderMode.EDIT) {
    return placeholder;
  }
  return path;
}
