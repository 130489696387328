import React from 'react';
import { ReactFeatureApp } from '@feature-hub/react';
import { FeatureAppDefinition, FeatureAppEnvironment } from '@feature-hub/core';
import {
  ContentContextProvider,
  FootnoteContextProvider,
  InViewContextProvider,
} from '@oneaudi/feature-app-utils';
import { createInitialState, deserializeState } from './utils/stateUtils';
import { ContextProvider } from './context';
import { AsyncStateHolder, Dependencies } from './FeatureAppTypes';
import AsyncFeatureApp from './FeatureApp';
import { mapHeadlessContent } from './utils/mapHeadlessContent';

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      'audi:envConfigService': '^1.0.0',
      'locale-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react-v2': '^3.4.0',
      '@feature-hub/react': '^3.5.0',
      react: '^17.0.2 || ^18.2.0',
      'react-dom': '^17.0.2 || ^18.2.0',
      'styled-components': '^5.3.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },
  create: ({ featureServices }: FeatureAppEnvironment<Dependencies, void>) => {
    const {
      'locale-service': localeService,
      's2:logger': logger,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      's2:async-ssr-manager': asyncSsrManager,
      's2:serialized-state-manager': serializedStateManager,
      'audi-content-service': contentService,
      'audi-tracking-service': trackingService,
      'dbad:audi-i18n-service': i18nService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
      'audi-render-mode-service': renderModeService,
    } = featureServices;

    logger?.info('Feature App created.');

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager?.getDefaultScopeRefService();

    if (trackingService) {
      trackingService.featureAppName = 'audi-feature-app-product-highlights-module';
    }
    const initialContent = contentService.getContent();
    console.log('initial Content:', initialContent);

    const content = mapHeadlessContent(initialContent, renderModeService?.getRenderMode?.() || 0);
    console.log('mapped Content: ', content);
    let asyncStateHolder: AsyncStateHolder;

    if (typeof asyncSsrManager !== 'undefined') {
      // on the server
      asyncSsrManager.scheduleRerender(
        (async () => {
          asyncStateHolder = await createInitialState(
            content,
            localeService,
            i18nService,
            vueFormatterService,
            logger,
          );
          serializedStateManager?.register(() => JSON.stringify(asyncStateHolder));
        })(),
      );
    } else {
      // on the client
      const serializedState = serializedStateManager?.getSerializedState();
      if (serializedState) {
        asyncStateHolder = deserializeState(serializedState);
      } else {
        logger?.warn(
          `Serialized state not found!. Possible reasons: \n 1. Running app in dev mode using the "audi-static-demo-integrator" which does not support SSR \n 2. Running app in prod mode and SSR is broken`,
        );
        asyncStateHolder = () =>
          createInitialState(content, localeService, i18nService, vueFormatterService, logger);
      }
    }

    return {
      render: () => {
        return (
          <InViewContextProvider logger={logger}>
            <ContentContextProvider contentService={contentService}>
              <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
                <div data-fefa-custom-id={footnoteReferenceService.getConsumerId()}>
                  <ContextProvider featureServices={featureServices}>
                    <AsyncFeatureApp asyncStateHolder={asyncStateHolder} />
                  </ContextProvider>
                </div>
              </FootnoteContextProvider>
            </ContentContextProvider>
          </InViewContextProvider>
        );
      },
    };
  },
};

export default featureAppDefinition;
