import {
  cleanupEmissionConsumptionMarkup,
  renderTextWithFootnotesReferencesV2,
} from '@oneaudi/feature-app-utils';
import { ConsumptionsAndEmissions } from '@oneaudi/vue-formatter-service';

export const formatWltpData = (wltpData: ConsumptionsAndEmissions[] | undefined) => {
  if (wltpData) {
    return wltpData.map((wltpItem) => {
      return {
        formattedConsumption:
          wltpItem?.formattedConsumption &&
          renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(wltpItem?.formattedConsumption),
          ),

        formattedEmission:
          wltpItem?.formattedEmission &&
          renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(wltpItem?.formattedEmission),
          ),
        formattedCo2Class:
          wltpItem.formattedCo2Class &&
          renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(wltpItem.formattedCo2Class),
          ),
        formattedDischargedConsumption:
          wltpItem.formattedDischargedConsumption &&
          renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(wltpItem.formattedDischargedConsumption),
          ),
        formattedDischargedCo2Class:
          wltpItem.formattedDischargedCo2Class &&
          renderTextWithFootnotesReferencesV2(
            cleanupEmissionConsumptionMarkup(wltpItem.formattedDischargedCo2Class),
          ),
      };
    });
  }
  return undefined;
};
