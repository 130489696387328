import React, { useMemo, useRef } from 'react';
import { ContextValue, State } from './ContextTypes';

export const Context = React.createContext<State>({} as State);

export const ContextProvider = ({ featureServices, children }: ContextValue): JSX.Element => {
  const {
    'dbad:audi-vue-formatter-service': vueFormatterService,
    'locale-service': localeService,
    'dbad:audi-i18n-service': i18nService,
    's2:logger': logger,
    'audi-tracking-service': trackingService,
    's2:async-ssr-manager': asyncSsrManager,
    's2:serialized-state-manager': serializedStateManager,
    'audi:envConfigService': envConfigService,
    'audi-content-service': contentService,
    'audi-render-mode-service': renderModeService,
  } = featureServices;
  const inViewRef = useRef<HTMLDivElement>(null);

  const state = useMemo(() => {
    return {
      logger,
      trackingService,
      inViewRef,
      asyncSsrManager,
      serializedStateManager,
      vueFormatterService,
      localeService,
      i18nService,
      envConfigService,
      contentService,
      renderModeService,
    };
  }, []);

  return (
    <Context.Provider value={state}>
      <div ref={inViewRef}>{children}</div>
    </Context.Provider>
  );
};
