import { ScreenOrientation } from '../FeatureAppTypes';
import { getOrientation } from './get-orientation';

export interface Orientation {
  orientation: ScreenOrientation;
}

export const useOrientation = (): Orientation => {
  const orientation = getOrientation();

  return {
    orientation,
  };
};
