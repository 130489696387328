import { audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react';
import { useContent } from '@oneaudi/feature-app-utils';
import React from 'react';
import styled from 'styled-components';
import { ImageProps, Content, ThemeGallery } from '../FeatureAppTypes';

const StyledPicture = styled.picture`
  min-height: 100%;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SvgImage = styled.img<{ testId: string }>`
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

export const Image: React.FC<ImageProps> = ({ url, alt, testId }) => {
  // TODO: Use Asset Helper to resolve size and rotation.
  const sizeS = '?width=375';
  const sizeM = '?width=768';
  const sizeL = '?width=1024';
  const sizeXL = '?width=1344';

  const themeGallery = useContent<Content>()?.themeGallery;
  const theme = themeGallery === ThemeGallery.DARK ? audiDarkTheme : audiLightTheme;
  return (
    <StyledPicture>
      <source
        srcSet={`${url}${sizeM} 2x, ${url}${sizeS}`}
        media={`(max-width: ${theme.breakpoints.s}px)`}
      />
      <source
        srcSet={`${url}${sizeL} 2x, ${url}${sizeM}`}
        media={`(max-width: ${theme.breakpoints.m}px)`}
      />
      <source
        srcSet={`${url}${sizeXL} 2x, ${url}${sizeL}`}
        media={`(max-width: ${theme.breakpoints.l}px)`}
      />
      <source
        srcSet={`${url}${sizeXL} 2x, ${url}${sizeXL}`}
        media={`(min-width: ${theme.breakpoints.l}px)`}
      />
      <StyledImage src={`${url}${sizeS}`} alt={alt} data-testid={testId} loading="lazy" />
    </StyledPicture>
  );
};
