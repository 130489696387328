import { Logger } from '@feature-hub/core';
import type { AssetSource } from '../EditorContentTypes';

export class AssetHelper {
  private static getAssetDataObject(jsonStrOrObj: AssetSource) {
    if (typeof jsonStrOrObj === 'string') {
      return JSON.parse(jsonStrOrObj);
    }
    return jsonStrOrObj;
  }

  private static replaceHost(url: string): string {
    if (url.indexOf('audivmstest-a.akamaihd.net') !== -1) {
      // pre-live host
      return url.replace(
        'audivmstest-a.akamaihd.net/brand/stage/brandportal/',
        'pre-www.audi.de/content-audi-assets/',
      );
    }
    if (url.indexOf('audivms-a.akamaihd.net') !== -1) {
      // live host
      return url.replace(
        'audivms-a.akamaihd.net/brand/stage/brandportal/',
        'www.audi.de/content-audi-assets/',
      );
    }
    return url;
  }

  static getImageUrl(jsonStrOrObj: AssetSource, logger: Logger | undefined): string {
    if (!jsonStrOrObj) return ''; // no image if no data is provided

    // JSON from content.audi => extract image URL
    try {
      // check for normal URL
      if (typeof jsonStrOrObj === 'string' && !jsonStrOrObj.startsWith('{')) {
        // standard URL
        return jsonStrOrObj;
      }
      // we have a json object
      const assetData = AssetHelper.getAssetDataObject(jsonStrOrObj);

      // check if it is an image or video
      if (Object.prototype.hasOwnProperty.call(assetData, 'image')) {
        const { imageFormats } = assetData.image[0];
        const { 'web-largest': webLargest } = imageFormats;
        if (!webLargest) {
          throw new Error('web-largest not found in imageFormats');
        }
        return AssetHelper.replaceHost(webLargest);
      }
      // no video data
      logger?.error(`Error retrieving image or video URL from JSON.`, jsonStrOrObj);
      return ''; // no image or video URL
    } catch (error) {
      logger?.error(
        `Error getting imageFormats["web-largest"] from image[0].`,
        jsonStrOrObj,
        error,
      );
      return ''; // no image in case of an error
    }
  }

  static getVideoUrl(jsonStrOrObj: AssetSource, logger?: Logger): string {
    if (!jsonStrOrObj) return ''; // no video if no data is provided

    // JSON from content.audi => extract video URL
    try {
      // check for normal URL
      if (typeof jsonStrOrObj === 'string' && !jsonStrOrObj.startsWith('{')) {
        // standard URL
        return jsonStrOrObj;
      }
      // we have a json object
      const assetData = jsonStrOrObj !== 'string' && AssetHelper.getAssetDataObject(jsonStrOrObj);

      // check if it is an image or video
      if (Object.prototype.hasOwnProperty.call(assetData, 'video')) {
        const { sourceFormats } = assetData.video[0];
        const { content1080p: fullHdUrl } = sourceFormats;
        if (!fullHdUrl) {
          throw new Error('content1080p not found in imageFormats');
        }
        return fullHdUrl;
      }
      // no video data
      logger?.error(`Error retrieving video URL from JSON.`, jsonStrOrObj);
      return ''; // no image or video URL
    } catch (error) {
      logger?.error(
        `Error getting sourceFormats["content1080p"] from video[0].`,
        jsonStrOrObj,
        error,
      );
      return ''; // no video URL in case of an error
    }
  }
}
