import * as React from 'react';

type UseIntersectionProps = {
  node: React.RefObject<HTMLElement>;
  root?: null | HTMLElement;
  rootMargin?: string;
  threshold?: number | number[];
  once?: boolean;
  callback?: (entry: IntersectionObserverEntry) => void;
};

export const useIntersection = ({
  node,
  root = null,
  rootMargin,
  threshold = 0,
  once = false,
  callback,
}: UseIntersectionProps): IntersectionObserverEntry | undefined => {
  const [entry, updateEntry] = React.useState<IntersectionObserverEntry>();
  const isClient = typeof window === 'object';

  const observer = isClient
    ? React.useRef(
        new window.IntersectionObserver(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          ([entry], observer) => {
            if (callback) callback(entry);
            else updateEntry(entry);
            if (once && entry.isIntersecting) {
              observer.disconnect();
            }
          },
          {
            root,
            rootMargin,
            threshold,
          },
        ),
      )
    : null;

  React.useEffect(() => {
    if (observer) {
      const { current: currentObserver } = observer;
      currentObserver.disconnect();

      if (node.current) {
        currentObserver.observe(node.current);
      }

      return () => {
        currentObserver.disconnect();
      };
    }
    return () => {
      // noop
    };
  }, [node]);

  return entry;
};
