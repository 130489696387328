/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';

interface Props {
  name: IconName;
  color?: string;
  size?: Size;
  responsive?: boolean;
}

interface StyledIconProps {
  iconSize?: Size;
  iconColor?: string;
  responsive?: boolean;
}

export enum Size {
  S = 24,
  M = 48,
}

export enum IconName {
  Forward,
  Back,
  Play,
  Pause,
  AudioOn,
  AudioOff,
}

const icons: { readonly [key: string]: React.ReactElement[] } = {
  [IconName.Forward]: [
    <path
      key="Forward"
      d="M10 16.9L15.5 11.5 10 6.1"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />,
  ],
  [IconName.Back]: [
    <path
      key="Back"
      d="M14 16.9L8.5 11.5 14 6.1"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />,
  ],
  [IconName.Play]: [
    <path
      key="Play"
      d="M9.5,7.4 L15.5,11.5 L9.5,15.7 L9.5,7.4 Z M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />,
  ],
  [IconName.Pause]: [
    <path
      key="Pause"
      d="M9.5,7 L9.5,16 M13.5,7 L13.5,16 M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />,
  ],
  [IconName.AudioOn]: [
    <path
      key="AudioOn"
      d="M5.5,9.5 L8.5,9.5 L8.5,13.5 L5.5,13.5 L5.5,9.5 Z M8.5,13.5 L11.5,15.4 L11.5,7.5 L8.5,9.5 M13.2,9.1 C13.2,9.1 16.2,10.9 13.2,13.9 M15.5,7.4 C15.5,7.4 20,11.3 15.5,15.7 M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />,
  ],
  [IconName.AudioOff]: [
    <path
      key="AudioOff"
      d="M11.5,22.5 C17.5751322,22.5 22.5,17.5751322 22.5,11.5 C22.5,5.42486775 17.5751322,0.5 11.5,0.5 C5.42486775,0.5 0.5,5.42486775 0.5,11.5 C0.5,17.5751322 5.42486775,22.5 11.5,22.5 Z M5.5,9.5 L8.5,9.5 L8.5,13.5 L5.5,13.5 L5.5,9.5 Z M8.5,13.5 L11.5,15.4 L11.5,7.5 L8.5,9.5 M14.1,10 C14.6,10.8 14.9,12.2 13.2,13.9 M15.9,7.8 C16.9,8.9 19.1,12.2 15.5,15.6 M17.8,2.5 L3.7,19.3 L17.8,2.5 Z"
      stroke="currentColor"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    />,
  ],
};

const StyledIcon = styled.svg<StyledIconProps>`
  width: ${({ iconSize }) => iconSize || '24'}px;
  height: ${({ iconSize }) => iconSize || '24'}px;
  color: ${({ iconColor }) => iconColor};
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    ${({ responsive }) =>
      responsive &&
      `
        width: ${Size.M}px;
        height: ${Size.M}px;
        
        * {
          stroke-width: 0.5;
        }
      `}
  }
`;

export const Icon: React.FC<Props> = ({ name, color, size, responsive }) => {
  const icon = icons[name];

  return (
    <StyledIcon
      iconColor={color}
      iconSize={size}
      responsive={responsive}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {icon}
    </StyledIcon>
  );
};
