import React, { useContext } from 'react';
import styled from 'styled-components';

import { UeElement } from '@oneaudi/falcon-tools';
import { AssetHelper } from '../helper/assetHelper';

import { SvgImage, Image } from './Image';
import Video from './Video';
import { Context } from '../context';
import { AssetSlideProps } from './ComponentTypes';

const StyledSlide = styled.div<{ active: boolean }>`
  ${({ active }) => active && `z-index: 1;`}
  position: relative;
  height: 48vh;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) and (orientation: portrait) {
    height: 65vh;
  }

  @media (orientation: landscape) {
    height: 100vh;
    .adobe-ue-edit & {
      // this variable is provided by the UE integration
      // it defines the initial viewport height in px
      // before the UE started resizing the iframe
      max-height: var(--ue-viewport-height);
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) and (orientation: landscape) {
    height: 100vh;
    .adobe-ue-edit & {
      // this variable is provided by the UE integration
      // it defines the initial viewport height in px
      // before the UE started resizing the iframe
      max-height: var(--ue-viewport-height);
    }
  }
`;

const AssetSlide: React.FC<AssetSlideProps> = ({
  active,
  content: { asset },
  backgroundColor,
  trackId,
  ...ueProps
}) => {
  const additionalStyles = {
    ...(backgroundColor ? { backgroundColor } : null),
  };
  const { logger } = useContext(Context);

  return (
    <StyledSlide style={additionalStyles} active={active} id={trackId} {...ueProps}>
      {asset && asset.assetType && asset.assetType === 'svg' && (
        <UeElement type="media" property="source" label="SvgImage">
          <SvgImage src={asset.svgUrl || ''} alt={asset.alt} testId="image" loading="lazy" />
        </UeElement>
      )}
      {asset && asset.assetType && asset.assetType === 'image' && (
        <UeElement type="media" property="source" label="Image">
          <Image
            url={AssetHelper.getImageUrl(asset.source?.assetValue, logger)}
            alt={asset.alt}
            testId="image"
          />
        </UeElement>
      )}
      {asset && asset.assetType && asset.assetType === 'video' && (
        <UeElement type="media" property="source" label="Video">
          <Video video={asset} active={active} />
        </UeElement>
      )}
    </StyledSlide>
  );
};

export default AssetSlide;
